import React from 'react'
import Collapsible from 'react-collapsible'
import styles from './accordion-content.module.scss'
import classNames from 'classnames'

const Accordions = ({ content }) => {
  return Object.keys(content).map(question => {
    return (
      <Collapsible
        className={classNames(styles.collapsible, styles.collapsibleHeader)}
        trigger={
          <h1>
            <strong>{question}</strong>
          </h1>
        }
        transitionTime={100}
      >
        <div
          className="content has-text-left"
          dangerouslySetInnerHTML={{ __html: content[question] }}
        />
        <br />
      </Collapsible>
    )
  })
}

export default Accordions
