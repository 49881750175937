import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AccordionPage from '../components/accordion-content/accordion-page'
import faqs from '../assets/faqs.json'
const Faq = props => (
  <Layout location={props.location}>
    <SEO
      title="Frequently Asked Questions"
      keywords={[
        `novartis`,
        `medgrocer`,
        `frequently asked questions`,
        'entresto',
      ]}
    />
    <AccordionPage
      content={faqs}
      title="Frequently Asked Questions"
      hasSubitems
    />
  </Layout>
)

export default Faq
