import React from 'react'
import ContentFold from '../page-content/content-fold'
import Accordions from './accordions'

const AccordionPage = ({
  content,
  isExpanded,
  title,
  hasPreface,
  hasSubitems,
  children,
}) => {
  return (
    <ContentFold title={title} isCentered>
      {hasPreface && (
        <div>
          <p>{children.props.children}</p>
          <br />
        </div>
      )}
      {hasSubitems
        ? Object.keys(content).map(title => {
            return (
              <div className="mb-3">
                <h1 className="subtitle">
                  <strong>{title}</strong>
                </h1>
                <Accordions content={content[title]} />
              </div>
            )
          })
        : Object.keys(content).map(title => {
            let item = {
              [title]: content[title],
            }
            return (
              <div className="mb-3">
                <Accordions content={item} />
              </div>
            )
          })}
    </ContentFold>
  )
}

export default AccordionPage
